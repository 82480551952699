import React, { useEffect, useState } from 'react';
import style from './aboutme.module.css';
import Page from '../../Components/Page/page';
import Section from '../../Components/Section/section';
import Title from '../../Components/Title/title';
import Button from '../../Components/Button/button';
import buttonstyle from '../../Components/Button/button.module.css';
import SmallestTitle from '../../Components/SmallestTitle/smallesttitle';
import ROUTES from '../../Routes/routes';
import { firebase } from '../../firebase';

import { IoSettingsSharp } from "react-icons/io5";
import { FaRunning } from "react-icons/fa";
import { FaPersonHiking } from "react-icons/fa6";
import { FaHeart } from "react-icons/fa";

import profilepicture from '../../Images/AboutMe/profile-picture.webp';

const AboutMe = () => {
  const [cvUrl, setCvUrl] = useState(null);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const cvRef = firebase.storage().ref('about me/Sarah BastienCV.pdf');
        const cvUrl = await cvRef.getDownloadURL();
        setCvUrl(cvUrl);
      } catch (error) {
        console.error('Error fetching images or CV:', error);
      }
    };

    fetchImages();
  }, []);

  const handleCvDownload = () => {
    if (cvUrl) {
      window.open(cvUrl, '_blank');
    } else {
      console.error("CV URL not available");
    }
  };

    return (
      <Page>
        <Section customClass={style.topSectoinAbout}>
          <div className={style.topSectionText}>
            <Title>Laten we kennis maken</Title>
            <p>
              Ik ben Sarah Bastien, nieuwsgierig en leergierig, steeds bereid om nieuwe werelden en 
              mensen te leren kennen. Ooit afgestudeerd als bioloog en gefascineerd door natuurlijke 
              ecosystemen, een interesse die ik - dankzij hooikoorts - geheroriënteerd heb naar het 
              ecosysteem van organisaties. Energiek en gemotiveerd om uitdagende doelstellingen te 
              realiseren, grenzen af te tasten en - waarom niet? - ook eens te verleggen. Daarbij komt 
              mijn doorzettingsvermogen goed van pas. Iets wat ook terugkomt in mijn hobby als 
              duursporter.
            </p>

            <p>
              En daar komen werk en hobby voor mij samen: in mijn ogen is elke organisatie een levend 
              organisme. Sportieve prestaties komen er niet zomaar: ze vragen een goed plan, structuur 
              om het plan uit te voeren en bijsturing waar nodig, om op het juiste moment een mooie 
              prestatie te kunnen neerzetten. Ook een organisatie vraagt vaak een plan om in vorm te 
              geraken en stappen vooruit te zetten.
            </p>

            <p className={style.topSectionTextMargin}>
              Door mijn ervaring ben ik een generalist die vanuit strategische vraagstukken de puzzel 
              weet te leggen, ook al is die complex. Zowel digitaliseren, aanpassen van de 
              werkorganisatie, procesoptimalisatie, data-analyse, draagkracht van de organisatie, 
              organisatieontwikkeling, teams motiveren om te veranderen, en ga zo maar door … komen 
              hierbij kijken. Bij al dat puzzelen stel ik steeds de waarden proactief, energiek, daadkrachtig 
              en oprecht voorop.
            </p>
            <Button link={ROUTES.contact}>Deel jouw uitdaging in een vrijblijvend gesprek</Button>
          </div>
          <img src={profilepicture} alt='profielfoto'/>
        </Section>

        <Section customClass={style.aboutMain}>
          <div className={style.aboutCards}>
            <div>
              <span>
                <IoSettingsSharp size={35} color='#FDFEFD'/>
              </span>
              <SmallestTitle>Proactief</SmallestTitle>
              <p>
                Oplossingen maak je voor morgen, dat vraagt flexibiliteit en zorgt ervoor dat onvoorziene wendingen
                en nieuwe opportuniteiten benut kunnen worden.
              </p>
            </div>

            <div>
              <span>
                <FaRunning size={35} color='#FDFEFD'/>
              </span>
              <SmallestTitle>Energiek</SmallestTitle>
              <p>
                Ik zet mensen aan tot beweging vanuit het organisatieverhaal. Beweging werkt aanstekelijk, zowel 
                mentaal als fysiek, voor mensen én organisaties.
              </p>
            </div>

            <div>
              <span>
                <FaPersonHiking size={35} color='#FDFEFD'/>
              </span>
              <SmallestTitle>Daadkrachtig</SmallestTitle>
              <p>
                Vooruitgang vraagt duidelijkheid en durf om te springen. Tot zichtbare resultaten komen is van
                 groot belang, zowel de succesvolle als de leerrijke.
              </p>
            </div>

            <div>
              <span>
                <FaHeart size={35} color='#FDFEFD'/>
              </span>
              <SmallestTitle>Oprecht</SmallestTitle>
              <p>
                Het organisatiedoel primeert en kan op mijn engagement rekenen. Ik communiceer transparant over 
                voortgang, eigen inzet en wederzijdse verwachtingen.
              </p>
            </div>
          </div>

          <div className={style.aboutText}>
            <p className={style.aboutTextFirst}>
              Ondertussen ben ik ruim 25 jaar aan de slag, zorgen voor verandering is vrij snel een rode 
              draad in mijn carrière geworden. Ik heb voor verandering gezorgd vanuit verschillende 
              invalshoeken: zowel vanuit een operationele en projectmatige invalshoek, als op strategisch 
              niveau vanuit programma- en directie-invalshoek.
            </p>

            <div>
              <SmallestTitle>Op directieniveau</SmallestTitle>
              <p>
                Met de volle verantwoordelijkheid om strategische doelen te bepalen en alle nodige te ondernemen om deze te 
                realiseren terwijl de dagelijkse werking blijft draaien.
              </p>
              <p className={style.aboutTextItalic}>
                Vb. de transitie van 6 stedelijke Gentse musea naar een overkoepelende
                organisatievorm en gedeelde zakelijke werking, van waaruit gericht gewerkt
                wordt naar nieuwe samenwerkingsdomeinen zoals beveiliging, depotwerking,
                marketing en communicatie, en digitalisering
              </p>
            </div>

            <div>
              <SmallestTitle>Op programmaniveau</SmallestTitle>
              <p>
                Door alle nodige projecten en activiteiten op te starten en te managen om de strategische doelen te bereiken.
              </p>
              <p className={style.aboutTextItalic}>
                Vb. overstap naar een gezamenlijke depotwerking voor de Gentse stedelijke 
                musea waarbij meer dan 30.000 objecten schoongemaakt, geregistreerd en 
                verpakt zijn om te verhuizen naar 2 nieuwe, gedeelde depots met een 
                gezamenlijke werking
              </p>
            </div>

            <div>
              <SmallestTitle>Vanuit operationele invalshoek</SmallestTitle>
              <p>
                Als teamleider van pas gevormde teams in een technologische omgeving.
              </p>
              <p className={style.aboutTextItalic}>
                Vb. opstarten van het nieuwe Team Kwaliteitscontrole waarin topografen, 
                fotogrammeters en GIS-operatoren samenwerken aan een geïntegreerd 
                kwaliteitsrapport voor extern geproduceerde geografische datasets 
              </p>
            </div>

            <div>
              <SmallestTitle>Vanuit projectmatige invalshoek</SmallestTitle>
              <p>
                Als business analist, projectleider en facilitator van verandertrajecten.
              </p>
              <p className={style.aboutTextItalic}>
                Vb. reduceren van de doorlooptijd met 3 maanden voor het volledige proces 
                van contracteren van een hotel tot het voor de klant boekbaar zijn van een 
                pakketreis
              </p>
            </div>

            <button onClick={handleCvDownload} className={buttonstyle.button}>Download mijn CV</button>
          </div>

        </Section>

      </Page>
    );
};

export default AboutMe;