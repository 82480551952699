import React, { useState } from 'react';
import style from './contact.module.css';
import Page from '../../Components/Page/page';
import Section from '../../Components/Section/section';
import Title from '../../Components/Title/title';
import { FaLocationDot } from "react-icons/fa6";
import buttonstyle from "../../Components/Button/button.module.css";
import { FaLinkedin } from "react-icons/fa";
import Input from '../../Components/Input/input';
import Text from '../../Components/Input/text';
import { firebase } from "../../firebase";

const Contact = () => {
    const [formData, setFormData] = useState({
        naam: '',
        voornaam: '',
        email: '',
        telefoonnummer: '',
        bericht: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
        setErrors({
            ...errors,
            [e.target.name]: ''
        });
    };

    const handleSubmit = async () => {
        const newErrors = {};

        Object.keys(formData).forEach(field => {
            if (!formData[field]) {
                newErrors[field] = 'Dit veld is verplicht';
            }
        });

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }


        try {
            const db = firebase.firestore();
            await db.collection('contact').add(formData);
            alert("Uw bericht is succesvol verzonden");
        } catch (error) {
            console.error("Error adding document: ", error);
            alert("Er ging iets mis probeer later nog eens opnieuw");
        }
    };

    return (
        <Page customClass={style.greenBackground}>
            <Section customClass={style.sectionRow}>
                <div className={style.contact}>
                    <div className={style.titleContact}>
                        <Title customClass={style.whiteTitle}>Neem contact op met Movim management!</Title>
                        <p>Deel gerust jouw uitdaging of zeg gewoon even hallo</p>
                    </div>
                    <div>
                        <span className={style.contactInfo}>
                            <FaLocationDot size={25} color='#668355'/>
                            <p>Gelegen in Gent, werkzaam in Vlaanderen en Brussel</p>
                        </span>
                        <span className={style.contactInfo}>
                            <FaLinkedin size={25} color='#668355'/>
                            <a href='https://www.linkedin.com/in/bastiensarah/' target="_blank" rel="noopener noreferrer">Linked in van Sarah Bastien</a>
                        </span>
                    </div>
                </div>

                <div className={style.form}>
                    <span>
                        <Input
                            name="naam"
                            placeholder="naam"
                            customClass={style.marginInput}
                            onChange={handleChange}
                            error={errors.naam}
                        />
                        <Input
                            name="voornaam"
                            placeholder="voornaam"
                            onChange={handleChange}
                            error={errors.voornaam}
                        />
                    </span>
                    <span>
                        <Input
                            name="email"
                            placeholder="email"
                            customClass={style.marginInput}
                            onChange={handleChange}
                            error={errors.email}
                        />
                        <Input
                            name="telefoonnummer"
                            placeholder="telefoonnummer"
                            onChange={handleChange}
                            error={errors.telefoonnummer}
                        />
                    </span>
                    <Text
                        name="bericht"
                        placeholder="bericht"
                        onChange={handleChange}
                        error={errors.bericht}
                    />
                    {errors.bericht && <p className={style.error}>{errors.bericht}</p>}
                    <p className={style.voorwaarden}>
                        Door op "Verzenden" te klikken, gaat u akkoord met het delen van uw gegevens met Movim Management.
                    </p>
                    <button onClick={handleSubmit} className={buttonstyle.button}>Verzenden</button>
                </div>
            </Section>
        </Page>
    );
};

export default Contact;
